"use strict";

/* Components */
// Vue instances
// Vue instance tickets-filters
$(function () {
  $('.js-tickets-filters').each(function () {
    var ticketsFilters = $(this); // const store = new Vuex.Store({
    //     strict: true,
    //     state: {
    //         tickets: [],
    //         filters: [],
    //         filtersSelected: {
    //             sortBy:     null,
    //             categories: [],
    //             priceFrom:  null,
    //             priceTo:    null,
    //             tiers:      []
    //         },
    //         filtersSelectedDefault: {},
    //         links: {
    //             filters: ticketsFilters.attr('data-filters-link')
    //         }
    //     },
    //     getters: {
    //         matches(state, getters) {
    //             let tickets = state.tickets.slice();
    //             return tickets;
    //         },
    //         filters(state, getters) {
    //             let filters = state.filters.slice();
    //             return filters;
    //         },
    //     },
    //     methods: {
    //         isObject (value) {
    //             return value && typeof value === 'object' && value.constructor === Object;
    //         },
    //         // Add/replace items in the state
    //         updateState(name, data, state, isReplace = false) {
    //             // If it is not an array
    //             if (Array.isArray(data)) {
    //                 // If replace
    //                 state[name] = isReplace ? [] : state[name];
    //                 // Add
    //                 data.map((item) => {
    //                     state[name].push(item);
    //                 });
    //             }
    //             else if (isObject(data)) {
    //                 for (let k in data) {
    //                     state[name] = data[k];
    //                 }
    //             }
    //             // Error
    //             else {
    //                 console.error('Error in updating of state `' + name + '`. It is not an array or object. Nothing was happened.');
    //             }
    //             return state;
    //         },
    //         replaceFilters(context) {
    //             axios
    //             .get(context.links.filters)
    //             .then((response) => {
    //                 if (typeof response.data.filters === 'undefined') {
    //                     console.error('Error in `Vue.mounted()`, `filters` are undefined or have incorrect JSON. Nothing was happened.');
    //                 }
    //                 else {
    //                     this.$store.commit('replaceFilters', response.data.filters);
    //                 }
    //             });
    //         }
    //     },
    //     mutations: {
    //         addLinks(state, links) {
    //             state = updateState('links', links, state, false);
    //         },
    //         replaceTickets(state, tickets) {
    //             state = updateState('tickets', tickets, state, true);
    //         },
    //         replaceFilters(state, filters) {
    //             state = updateState('filters', filters, state, true);
    //         },
    //         updateFiltersSelected(state, filtersSelected) {
    //             state = updateState('filtersSelected', filtersSelected, state);
    //         },
    //         resetFiltersSelected(state) {
    //             state.filtersSelected = Object.assign({}, state.filtersSelectedDefault);
    //         },
    //         setFiltersSelectedDefaultAsFilterSelected(state) {
    //             state.filtersSelectedDefault = Object.assign({}, state.filtersSelected);
    //         },
    //     }
    // })

    new Vue({
      components: {
        VueSlider: window['vue-slider-component']
      },
      data: {
        price: [200, 800]
      },
      el: '#app',
      //store,
      mounted: function mounted() {}
    });
  });
}); // Other
// Turn off links with "#" href

$(function () {
  $('a[href="#"]').off('click.empty-link').on('click.empty-link', function () {
    return false;
  });
}); // Dropdown

$(function () {
  // Don't close dropdown by click inside
  $(document).on('click', '.dropdown-menu', function () {
    return false;
  }); // Close only when button with class '.js-dropdown-close' was clicked

  $(document).on('click', '.dropdown-menu .js-dropdown-close', function () {
    var dropdownCloser = $(this),
        dropdown = dropdownCloser.closest('.dropdown'),
        dropdownMenu = dropdown.find('.dropdown-menu');
    dropdown.removeClass('show');
    dropdownMenu.removeClass('show');
  });
}); // Nav sticky

$(function () {
  $('.js-nav-sticky').each(function () {
    var wrapper = $(this);
    $(window).on('scroll.nav-sticky resize.nav-sticky', function () {
      // If window was scrolled
      if ($(window).scrollTop()) {
        wrapper.addClass('visible');
      } else {
        wrapper.removeClass('visible');
      }
    }); // Init after page was loaded

    $(window).scroll();
  });
}); // Countdown

$(function () {
  $('.js-countdown').each(function () {
    var blockCountdown = $(this),
        blockDays = blockCountdown.find('.js-countdown-days'),
        blockHours = blockCountdown.find('.js-countdown-hours'),
        blockMinutes = blockCountdown.find('.js-countdown-minutes'),
        blockSeconds = blockCountdown.find('.js-countdown-seconds'),
        datetime = moment(blockCountdown.attr('data-datetime'));
    updateViewInfinity(); // Update remaining datetime in HTML

    function updateViewInfinity() {
      var differenceDays = 0,
          differenceHours = 0,
          differenceMinutes = 0,
          differenceSeconds = 0,
          now = moment(),
          difference = moment.utc(datetime.diff(now));

      if (difference >= 0) {
        differenceDays = datetime.diff(now, 'days');
        differenceHours = difference.format('H');
        differenceMinutes = difference.format('m');
        differenceSeconds = difference.format('s');
        setTimeout(function () {
          updateViewInfinity();
        }, 1000);
      }

      blockDays.text(differenceDays);
      blockHours.text(differenceHours);
      blockMinutes.text(differenceMinutes);
      blockSeconds.text(differenceSeconds);
    }
  });
}); // Scroll down

$(function () {
  $('.js-scroll-down').each(function () {
    var button = $(this),
        stickyNav = $('.js-nav-sticky');
    button.off('click.scroll-down').on('click.scroll-down', function () {
      // scrollDistance = window.height
      var scrollDistance = $(window).innerHeight() + 1; // scrollDistance = scrollDistance - stickyNav.height

      stickyNav.each(function () {
        scrollDistance -= $(this).innerHeight();
      }); // Scrolling

      $('html,body').animate({
        scrollTop: scrollDistance
      }, 700);
    });
  });
}); // Notice

$(function () {
  var noticeWrappers = $('.js-notice-wrapper');

  if (noticeWrappers.length > 0) {
    var noticeWrapperMobile = noticeWrappers.filter('.notice-mobile'),
        noticeWrapperDesktop = noticeWrappers.filter('.notice-desktop'),
        noticeWrapperFixed = noticeWrappers.filter('.notice-fixed'),
        noticeFixed = noticeWrapperFixed.find('.js-notice');
    $(window).on('scroll.notice resize.notice', function () {
      // We will check mobile block or desktop block
      var noticeWrapperStatic = noticeWrapperMobile;

      if (noticeWrapperMobile.css('display') === 'none') {
        noticeWrapperStatic = noticeWrapperDesktop;
      } // Show static/fixed notice


      if (noticeWrapperStatic.offset().top > noticeWrapperFixed.offset().top) {
        noticeWrapperStatic.find('.js-notice').addClass('shown');
        noticeFixed.removeClass('shown');
      } else {
        noticeWrapperStatic.find('.js-notice').removeClass('shown');
        noticeFixed.addClass('shown');
      }
    });
  }
}); // Share

$(function () {
  $('.js-shares').each(function () {
    var sharesWrapper = $(this),
        sharesButtons = sharesWrapper.find('.js-share');
    notices = $('.js-notice'); // Copy to clipboard

    function copyToClipboard(str, offsetTop) {
      var el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      el.style.top = offsetTop;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }

    ; // Show notice for some time

    function notify(message, duration) {
      if (!notices.first().hasClass('active')) {
        notices.text(message);
        notices.addClass('active');
        setTimeout(function () {
          notices.removeClass('active');
        }, duration);
      }
    } // Click


    sharesButtons.off('click.share').on('click.share', function () {
      var shareButton = $(this); // Copy link to clipboard

      if (shareButton.is('[data-share-copy]')) {
        copyToClipboard(window.location.href, shareButton.offset().top + 'px');
        notify(shareButton.attr('data-share-copy'), 3000);
        return false;
      } // Share by 'data-share-href' attr


      if (shareButton.is('[data-share-href]')) {
        shareButton.attr('href', shareButton.attr('data-share-href') + encodeURIComponent(window.location.href));
      }
    });
  });
}); // Close current modal and open another

$(function () {
  $('.js-modal-close-current-open-another').each(function () {
    var btn = $(this),
        currentModal = $(this).closest('.modal'),
        anotherSelector = btn.attr('data-target'),
        anotherModal = $(anotherSelector);
    btn.off('click.modal-close-current-open-another').on('click.modal-close-current-open-another', function () {
      currentModal.one('hidden.bs.modal', function () {
        anotherModal.modal('show');
      });
      currentModal.modal('hide');
    });
  });
}); // Don't submit the form

$(function () {
  $('.js-no-submit').each(function () {
    $(this).off('submit.no-submit').on('submit.no-submit', function () {
      return false;
    });
  });
}); // Slide toggle

$(function () {
  $('.js-slide-toggle-btn').each(function () {
    var button = $(this),
        name = button.attr('data-slide-toggle-target'),
        target = $('.js-slide-toggle-target[data-slide-toggle-name="' + name + '"]');
    button.off('click.slide-toggle').on('click.slide-toggle', function () {
      target.stop().slideToggle(300);
    });
  });
}); // Nav tabs component
// Sticky

$(function () {
  $('.js-nav-tabs-sticky').each(function () {
    var navHeader = $('.js-nav-sticky').first();
    var wrapperForCopy = navHeader.find('.js-nav-sticky-target');
    var originalNav = $(this);
    var originalNavWrapper = $(this).closest('.js-nav-tabs-wrapper');
    var cloneNavWrapper = originalNavWrapper.clone(true);
    var copyNav = cloneNavWrapper.find('.js-nav-tabs-sticky');
    var navStickySection = $('.js-nav-sticky-section').first();
    cloneNavWrapper.addClass('copy');
    wrapperForCopy.append(cloneNavWrapper); // Add sticky functionality for the copied nav

    $(window).off('scroll.nav-tabs-sticky').on('scroll.nav-tabs-sticky', function () {
      var navHeaderHeight = navHeader.innerHeight();
      var copyNavHeight = 0;

      if (!navStickySection.hasClass('hidden')) {
        copyNavHeight = copyNav.innerHeight();
      }

      var offsetTop = navHeaderHeight - copyNavHeight;

      if ($(window).scrollTop() >= originalNav.offset().top - offsetTop / 2) {
        navStickySection.removeClass('hidden');
      } else {
        navStickySection.addClass('hidden');
      }
    });
  });
}); // Switching

$(function () {
  var navTabs = $('.js-nav-tabs');

  if (navTabs.length > 0) {
    var getCurrentScrollPositionMultiplier = function getCurrentScrollPositionMultiplier() {
      return $(window).scrollTop() / $(document).innerHeight();
    };

    var getRemembedScrollPositionMultiplier = function getRemembedScrollPositionMultiplier(tabName, tabs) {
      return parseFloat(tabs.filter('[data-tab=' + tabName + ']').attr('data-scroll-position-multiplier'));
    };

    var getNavTabsPosition = function getNavTabsPosition() {
      return navTabsOriginal.offset().top - navStickyWrapper.innerHeight();
    };

    var scrollToRemembedPosition = function scrollToRemembedPosition(tabName, tabs) {
      var remembedScrollPositionMultiplier = getRemembedScrollPositionMultiplier(tabName, tabs);
      var positionToScroll = remembedScrollPositionMultiplier ? remembedScrollPositionMultiplier * $(document).innerHeight() : null;
      var navTabsPosition = getNavTabsPosition(); // Scroll to the Nav tabs if no remembed multiplier & user is below navTabs 

      if (!positionToScroll && $(window).scrollTop() > navTabsPosition || positionToScroll && positionToScroll < $(window).scrollTop()) {
        positionToScroll = navTabsPosition;
      } // Scroll


      if (positionToScroll) {
        $("html, body").animate({
          scrollTop: positionToScroll
        }, 500);
      }
    }; // Go to the Tab function


    var navTabsOriginal = navTabs.closest('.nav-tabs-wrapper').filter(':not(.copy)');
    var navStickyWrapper = $('.js-nav-sticky');
    var tabsLinks = $('.js-nav-link');
    var activeTabsName = '';
    var tabsWrapper = $('.js-tabs');
    var tabs = $('.js-tab');
    var tabEventInfoAdditional = $('.js-event-info');
    var blocksToHide = $('.js-nav-tab-hidden');

    var goTo = function goTo(tabName) {
      // Remember scroll position
      tabs.filter('.active').attr('data-scroll-position-multiplier', getCurrentScrollPositionMultiplier()); // remember start wrapper's height for transition

      var currentHeight = tabsWrapper.innerHeight();
      tabsWrapper.css('height', currentHeight);
      tabsWrapper.addClass('active'); // Close opened tab

      tabsLinks.removeClass('active');
      tabs.removeClass('active'); // Find active tab

      var activeTabsLinks = tabsLinks.filter('[data-tab=' + tabName + ']'); // If we not have tab with this name, switch first

      if (activeTabsLinks.length <= 0) {
        tabName = tabsLinks.first().attr('data-tab');
        activeTabsLinks = tabsLinks.filter('[data-tab=' + tabName + ']');
      }

      activeTabsLinks.addClass('active');
      var nextTab = tabs.filter('[data-tab=' + tabName + ']');
      nextTab.addClass('active'); // For wrapper transition

      tabsWrapper.css('height', nextTab.innerHeight()); // Make active tab visible by scrolling

      navTabs.each(function () {
        var tabActive = $(this).find('.js-nav-link.active').closest('.js-nav-item');
        $(this).animate({
          scrollLeft: tabActive.offset().left
        }, 500);
      }); // Scroll to remembed position and 
      // remove fixed height of wrapper
      // after transition

      tabsWrapper.one('transitionend.nav-tabs', function () {
        scrollToRemembedPosition(tabName, tabs);
        tabsWrapper.css('height', '');
      }); // Show/hide specific blocks

      blocksToHide.stop().slideDown(250);
      blocksToHide.filter('[data-nav-tab-hidden="' + tabName + '"]').each(function () {
        $(this).stop().slideUp(250);
      }); // Remember active tab's name

      activeTabsName = tabName; // For the Event info tab

      if (tabName === 'event-info') {
        tabEventInfoAdditional.addClass('shown');
        tabEventInfoAdditional.slideDown(250, function () {
          $('.js-sidebar-sticky').each(function () {
            $(this).stickySidebar('updateSticky');
          });
        });
      } else {
        tabEventInfoAdditional.removeClass('shown');
        tabEventInfoAdditional.slideUp(250);
      }
    }; // Turn on the active or first tab


    var hashInfo = window.location.hash.match(/(#tab-)(.+)$/i);

    if (hashInfo != null) {
      activeTabsName = hashInfo[2];
    } else {
      activeTabsName = tabsLinks.first().attr('data-tab');
      window.location.hash = '#tab-' + activeTabsName;
    }

    goTo(activeTabsName); // Click by the Tab link

    tabsLinks.off('click.nav-tabs').on('click.nav-tabs', function () {
      if ($(this).attr('data-tab') === activeTabsName) return false;
      goTo($(this).attr('data-tab'));
    });
  }
}); // Show-hide component

$(function () {
  $('.js-show-hide-btn').each(function () {
    var btn = $(this),
        target = $('.js-show-hide-target[data-show-hide=' + btn.attr('data-show-hide') + ']'),
        targetOpposite = $('.js-show-hide-target-opposite[data-show-hide=' + btn.attr('data-show-hide') + ']'),
        btnContent = btn.find('.js-show-hide-content'),
        contentShown = btnContent.attr('data-show-hide-content'),
        contentHidden = btnContent.text(),
        isShown = false;
    btn.off('click.show-hide').on('click.show-hide', function () {
      // Hide
      if (isShown) {
        target.one('transitionend.show-hide', function () {
          target.stop().slideUp(function () {
            target.css('height', 'auto');
          });
          targetOpposite.stop().slideDown(function () {
            targetOpposite.css('height', 'auto');
          });
        });
        target.removeClass('shown');
        btnContent.text(contentHidden);
        isShown = false;
      } // Show
      else {
          target.off('transitionend.show-hide');
          target.stop().slideDown(function () {
            target.css('height', 'auto');
            target.addClass('shown');
          });
          targetOpposite.stop().slideUp(function () {
            targetOpposite.css('height', 'auto');
          });
          btnContent.text(contentShown);
          isShown = true;
        }
    });
  });
});
$(function () {
  $(".quote-carousel").owlCarousel({
    items: 1,
    loop: true
  });
  $(".artist-carousel").owlCarousel({
    loop: true,
    responsive: {
      0: {
        center: true,
        items: 3,
        margin: 10,
        dots: false
      },
      768: {
        margin: 15,
        items: 2,
        dots: true
      },
      992: {
        margin: 15,
        items: 3
      }
    }
  });
}); // Gallery

$(function () {
  $('.js-slider').each(function () {
    var slider = $(this);
    var previews = $('.js-slider-preview');
    var modal = $('#modal-slider-event-info').first();
    var modalDialog = modal.find('.modal-dialog').first();
    var desktopThumbItem = 7;
    var slidesCountToCenterSlide = Math.ceil(desktopThumbItem / 2);
    slider.lightSlider({
      gallery: true,
      item: 1,
      slideMargin: 0,
      thumbItem: 7,
      galleryMargin: 10,
      thumbMargin: 10,
      responsive: [{
        breakpoint: 1199,
        settings: {
          thumbMargin: 10
        }
      }, {
        breakpoint: 991,
        settings: {
          thumbMargin: 10,
          thumbItem: 6
        }
      }, {
        breakpoint: 767,
        settings: {
          thumbItem: 5,
          galleryMargin: 28
        }
      }, {
        breakpoint: 575,
        settings: {
          thumbItem: 4
        }
      }, {
        breakpoint: 479,
        settings: {
          thumbItem: 3
        }
      }],
      onBeforeSlide: function onBeforeSlide() {
        // Show/hide white gradient on thumbnails
        var slideNum = slider.getCurrentSlideCount();
        var slidesTotal = slider.getTotalSlideCount(); // Because getTotalSlideCount() returns incorrect value sometimes
        // It is fix for these cases

        if (typeof slideNum === 'string') {
          slideNum = slideNum.slice(0, -1);
          slideNum = parseInt(slideNum);
          slideNum++;
        }

        if (slidesCountToCenterSlide - slideNum >= 0) {
          modalDialog.removeClass('thumbnail-gradient-left');
        } else {
          modalDialog.addClass('thumbnail-gradient-left');
        }

        if (slidesTotal - slidesCountToCenterSlide - slideNum < 0) {
          modalDialog.removeClass('thumbnail-gradient-right');
        } else {
          modalDialog.addClass('thumbnail-gradient-right');
        }
      }
    }); // Open modal when image was clicked

    previews.off('click.slider').on('click.slider', function () {
      var indexNumber = $(this).attr('data-index-number');
      modal.modal('show');
      slider.goToSlide(indexNumber);
      return false;
    }); // We need to refresh slider after opening the modal.
    // And use .active class for animation after refreshing.

    modal.off('show.bs.modal').on('show.bs.modal', function () {
      modalDialog.removeClass('active');
      $('body').addClass('backdrop-slider');
    });
    modal.off('shown.bs.modal').on('shown.bs.modal', function () {
      slider.refresh();
      modalDialog.addClass('active');
    });
    modal.off('hidden.bs.modal').on('hidden.bs.modal', function () {
      $('body').removeClass('backdrop-slider');
    });
    $('.slideControls .slidePrev').click(function () {
      slider.goToPrevSlide();
    });
    $('.slideControls .slideNext').click(function () {
      slider.goToNextSlide();
    });
  });
  $('.js-slider-map').each(function () {
    var slider = $(this);
    var modal = $('#modal-slider-map').first();
    var modalDialog = modal.find('.modal-dialog').first();
    slider.lightSlider({
      item: 1,
      controls: false,
      pager: false,
      gallery: false,
      enableTouch: false,
      enableDrag: false,
      freeMove: false
    }); // We need to refresh slider after opening the modal.
    // And use .active class for animation after refreshing.

    modal.off('show.bs.modal').on('show.bs.modal', function () {
      modalDialog.removeClass('active');
      $('body').addClass('backdrop-slider');
    });
    modal.off('shown.bs.modal').on('shown.bs.modal', function () {
      slider.refresh();
      modalDialog.addClass('active');
    });
    modal.off('hidden.bs.modal').on('hidden.bs.modal', function () {
      $('body').removeClass('backdrop-slider');
    });
  });
});
$(function () {
  // Sticky sidebar
  var offsetTop = 0;
  $('.js-nav-sticky > :nth-last-child(2)').each(function () {
    offsetTop += this.offsetTop;
    offsetTop += $(this).children().innerHeight();
  });
  $('.js-sidebar-sticky').each(function () {
    $(this).stickySidebar({
      topSpacing: offsetTop,
      bottomSpacing: 0,
      resizeSensor: true,
      minWidth: 990
    });
  }); // Sticky bar mobile

  function stickyBarMobile() {
    var packages_tab = $('.js-tab.active').data('tab'),
        container = $('.js-sticky-bar-holder');

    if (packages_tab === "tickets") {
      container.css('display', 'none');
    } else {
      container.css('display', 'block');
    }
  }

  stickyBarMobile();
  $('.js-nav-item, .js-nav-link').on('click', function () {
    stickyBarMobile();
  });
  $('.js-sticky-bar-mobile').each(function () {
    var sticky = $(this);
    $(window).on('scroll.sticky', function () {
      var scrollTop = $(document).scrollTop();
      var stickyParent = sticky.parent();
      var stickyHeight = sticky.innerHeight();
      var stickyRealPosition = stickyParent.offset().top - $(window).outerHeight() + stickyHeight;

      if (scrollTop > 50) {
        sticky.addClass('visible');
      } else {
        sticky.removeClass('visible');
      }

      if (scrollTop < stickyRealPosition) {
        sticky.addClass('fixed');
        stickyParent.css('padding-top', stickyHeight + 'px');
      } else {
        sticky.removeClass('fixed');
        stickyParent.css('padding-top', '0');
      }
    });
    $(window).scroll();
  });
}); // Show-hide component

$(function () {
  $('.js-to-top').each(function () {
    var btn = $(this),
        offset = btn.attr('data-offset');
    btn.off('click.to-top').on('click.to-top', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 500);
    });
    $(window).on('scroll.to-top', function () {
      if ($(window).scrollTop() >= offset) {
        btn.addClass('shown');
      } else {
        btn.removeClass('shown');
      }
    });
    $(window).scroll();
  });
});